.table-box[data-v-a24fd5a0] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
[data-v-a24fd5a0] .common_tree_handle .el-tree {
  height: 55vh !important;
  max-height: 55vh !important;
}
[data-v-a24fd5a0] .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}